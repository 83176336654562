import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import Autoplay from 'embla-carousel-autoplay'
import useEmblaCarousel from 'embla-carousel-react'
import { EmblaOptionsType } from 'embla-carousel'
import "./css/BannerCarousel.css"
import { BannerCarouselDotButton, useDotButton } from './buttons/BannerCarouselDotButton'
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures'
import { isMatch } from 'date-fns'

interface Props {
  img_srcs: string[]
  delay?: number;
  isMobile?: boolean;
  borderRadius?: number;
  aspectRatio?: string;
  playOnInit?: boolean;
  IDs?: (string|null)[];
  isSelectedBorder?: boolean;
  setSelectedID?: Dispatch<SetStateAction<string|null>>;
}


const BannerCarousel: React.FC<Props> = ({ playOnInit, delay, img_srcs, isMobile, IDs, setSelectedID, borderRadius, aspectRatio, isSelectedBorder }) => {
  const options: EmblaOptionsType = { loop: true, skipSnaps: true, axis: 'x', dragFree: false}
  const [isPlaying, setIsPlaying] = useState(false)
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [
    Autoplay({ 
      playOnInit: playOnInit ?? true, 
      delay: delay ? delay : 3000
    }),
    WheelGesturesPlugin({
    }),
  ])
  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi)

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [wheelSelectedIndex, setWheelSelectedIndex] = useState(0)
  const [wheelScrollSnaps, setWheelScrollSnaps] = useState<number[]>([])

  useEffect(() => {
    const autoplay = emblaApi?.plugins()?.autoplay
    const wheelScroll = emblaApi?.plugins()?.wheelGestures
    if (!autoplay || !wheelScroll) return

    setIsPlaying(autoplay.isPlaying())
    emblaApi
      .on('autoplay:play', () => setIsPlaying(true))
      .on('autoplay:stop', () => setIsPlaying(false))
      .on('reInit', () => setIsPlaying(autoplay.isPlaying()))

    const onSelect = () => {
      setWheelSelectedIndex(emblaApi.selectedScrollSnap())
      setPrevBtnEnabled(emblaApi.canScrollPrev())
      setNextBtnEnabled(emblaApi.canScrollNext())
    }

    setWheelScrollSnaps(emblaApi.scrollSnapList())
    emblaApi.on('select', onSelect)
    onSelect()

  }, [emblaApi])

  useEffect(() => {
    if(setSelectedID && IDs) setSelectedID(IDs[selectedIndex])
  }, [selectedIndex])


  return (
    <div style={{}} className="banner">
      <div className="banner__viewport" ref={emblaRef}>
        <div className="banner__container">
          {img_srcs.map((banner, index) => (
            <div className="banner__slide" key={banner}  >
              <div className="banner__slide__number">
                <img src={banner} alt='画像' style={{ border: (isMobile !== true && index === selectedIndex && isSelectedBorder) ? "1px solid skyblue" : "none", aspectRatio:aspectRatio ?? undefined, verticalAlign: "top", objectFit: "cover",  borderRadius: borderRadius ?? undefined, width: "100%"}}/>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="banner__controls">
        <div className="banner__dots">
          {scrollSnaps.map((_, index) => {
            return(
              <BannerCarouselDotButton
                key={index}
                onClick={() => onDotButtonClick(index)}
                className={'banner__dot'.concat(
                  index === selectedIndex ? ' banner__dot--selected' : ''
                )}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default BannerCarousel