import React, { useState, useContext, useEffect, useRef } from 'react';
import { Button, Box, Divider, Typography, Stack, Avatar, Backdrop, Fade, TextField, Popover } from '@mui/material';
import Modal from '@mui/material/Modal';
import { useLogout } from "../API/users";
import { MyContext } from '../../routes/router';
import UserIcon from '../User/icon';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import FavoriteIcon from '@mui/icons-material/Favorite';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import HelpIcon from '@mui/icons-material/Help';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import HomeIcon from '@mui/icons-material/Home';

interface TagsProps {
    user_id: string | undefined,
    user_name: string;
    profile_image_name?: string | null;
    profile_image_uri?: string | null
    user_type: number | null;
    anchorEL: HTMLElement | null;
    setAnchorEL: (element: HTMLElement | null) => void;
}

const ProfilePopover: React.FC<TagsProps> = ({ user_id, user_name, profile_image_name, profile_image_uri, user_type, anchorEL, setAnchorEL }) => {
    
    const { openBetaLimitPopup } = useContext(MyContext);
    const mutateLogout = useLogout()

    if(user_type == null){
        return null;
    }



    const fontSize = 14
    const padding = "10px 24px"
    const padding2 = "10px 24px"
  return (
    <Popover open={Boolean(anchorEL)}
        
      onClose={ () => {setAnchorEL(null)}}
      anchorEl={anchorEL}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      disableRestoreFocus>
        <Stack width={"100%"} alignItems={"center"} zIndex={2} sx={{position:"relative"}}>
            <Stack onClick={()=>{}} justifyContent={"center"} alignItems={"center"} sx={{width:"100%" }}>
                <Typography sx={{width:"100%"}} textAlign={"center"} component="div" >
                    <Stack spacing={1.5} padding={padding} width={"100%"} justifyContent={"flex-start"} direction={"row"} alignItems={"center"} onClick={() => {}} sx={{}}>
                        <UserIcon cursor='' user_name={user_name} onClick={()=>{}} radius={20} profile_image_name={profile_image_name} profile_image_uri={profile_image_uri}/>
                        <Typography sx={{ fontSize: fontSize, color:"#616161" }} textAlign={"center"} component="div" >{user_name}</Typography>
                    </Stack>
                </Typography>
            </Stack>


            <Stack onClick={()=>{window.location.href = "https://www.re-idea.jp"}} justifyContent={"center"} alignItems={"center"} sx={{width:"100%" }}>
                <Divider sx={{width:"90%"}}/>
                <Typography sx={{width:"100%"}} textAlign={"center"} component="div" >
                    <Stack padding={padding} width={"100%"} justifyContent={"flex-start"} direction={"row"} alignItems={"center"} onClick={() => {}} sx={{cursor:"pointer", ":hover":{backgroundColor:"snow"},  fontSize: fontSize, color:"#616161",  fontWeight: 'medium',  }}>
                        <HelpIcon sx={{fontSize:24, marginRight:"12px"}}/>{"RE-IDEAとは"}
                    </Stack>
                </Typography>
            </Stack>

            <Stack onClick={()=>{window.location.href = "/"}} justifyContent={"center"} alignItems={"center"} sx={{width:"100%" }}>
                <Typography sx={{width:"100%"}} textAlign={"center"} component="div" >
                    <Stack padding={padding} width={"100%"} justifyContent={"flex-start"} direction={"row"} alignItems={"center"} onClick={() => {}} sx={{cursor:"pointer", ":hover":{backgroundColor:"snow"},  fontSize: fontSize, color:"#616161",  fontWeight: 'medium',  }}>
                        <HomeIcon sx={{fontSize:24, marginRight:"12px"}}/>{"TOPへ"}
                    </Stack>
                </Typography>
            </Stack>

            <Stack onClick={()=>{window.location.href = "/mypage"}} justifyContent={"center"} alignItems={"center"} sx={{width:"100%" }}>
                <Typography sx={{width:"100%"}} textAlign={"center"} component="div" >
                    <Stack padding={padding} width={"100%"} justifyContent={"flex-start"} direction={"row"} alignItems={"center"} onClick={() => {}} sx={{cursor:"pointer", ":hover":{backgroundColor:"snow"},  fontSize: fontSize, color:"#616161",  fontWeight: 'medium',  }}>
                        <TipsAndUpdatesIcon sx={{fontSize:24, marginRight:"12px"}}/>{"マイページ"}
                    </Stack>
                </Typography>
            </Stack>


            <Stack onClick={() => {mutateLogout.mutate()}} justifyContent={"center"}  alignItems={"center"} sx={{width:"100%" }}>
                <Divider sx={{width:"90%"}}/>
                <Typography sx={{width:"100%"}} textAlign={"center"} component="div" >
                    <Stack p={padding2} width={"100%"} justifyContent={"flex-start"} direction={"row"} alignItems={"center"} onClick={() => {}} sx={{cursor:"pointer", ":hover":{backgroundColor:"snow"},  fontSize: fontSize, color:"red",  fontWeight: 'medium',  }}>
                        <LogoutIcon sx={{fontSize:24, color:"red", marginRight:"12px"}}/>ログアウト
                    </Stack>
                </Typography>
            </Stack>

        </Stack>
    </Popover>
  );
};

export default ProfilePopover;