
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { simple } from '../../interfaces/room';


// chatgptにサブタイトルとアピールポイントを出力してもらう
export const OpenAiChatGpt_Get_SubTitle_Point = ( title:string, description: string, success_func: (res:any) => void, fail_func: () => void ) => {
    axios.post(process.env.REACT_APP_API_URL+'/openai/chatgpt/3.5/subtitle_points', {title: title, description: description}, {
        }).then(res => {
            if(Number(res.data.result.status) === 200){
                success_func(res)
            }else{
                fail_func()
            }
      })
      .catch((err) => {
          console.log(err);
      });
}

// chatgptにmidjourney用のtextを出力してもらう
export const OpenAiChatGpt_Get_Midjourney_Text = ( title:string, description: string, success_func: (res:any) => void, fail_func: () => void ) => {
    axios.post(process.env.REACT_APP_API_URL+'/openai/chatgpt/3.5/midjourney_text', {title: title, description: description}, {
        }).then(res => {
            if(Number(res.data.result.status) === 200){
                success_func(res)
            }else{
                fail_func()
            }
      })
      .catch((err) => {
          console.log(err);
      });
}
