import axios from 'axios';
import { UseMutationResult, UseQueryResult, useMutation, useQuery } from 'react-query';

interface PutIdeaPayload {
    request_id: string | null;
    newValues: { [key: string]: string | Blob };
}


// GET POST PUT DELTEの4種類のみで構成されていることが理想

// GET /requests/type/idea_id or query
export const useGetRequests = <T,>(query_key: string, type: "simple" | "medium", request_id: string | null, query?: string): UseQueryResult<T, Error> & { queryKey: string } => {
    // ideas/ simple or medium (/) idea_id or (?) query
    const API_END_POINT_PASS = `/requests/${type ?? ""}${request_id ? "/"+request_id : ""}${query ? "?"+query : ""}`
    const QUERY_KEY = query_key;
    return {
      ...useQuery([QUERY_KEY, request_id], () => fetch(process.env.REACT_APP_API_URL+API_END_POINT_PASS).then(res =>res.json())),
      queryKey:QUERY_KEY,
    };
}

// DELETE /requests/request_id
export const useDeleteRequest = (): UseMutationResult<void, Error, string> => {
    return useMutation(async (request_id: string) => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/requests/${request_id}`, {
        method: 'DELETE',
        credentials: "include",
      });
  
      if (!response.ok) {
        throw new Error('Failed to delete idea');
      }
    });
};


// PUT /requests/request_id
export const usePutIdea = (): UseMutationResult<void, Error, PutIdeaPayload> => {
    return useMutation(async ({ request_id, newValues }: PutIdeaPayload) => {
        // newValuesで指定されているFile系の値を送信用に変換したりする
        const formData = new FormData();

        // "file" という名前のキーを除外した新しいオブジェクトを作成
        const updateFields: { [key: string]: any } = {};
        for(let key in newValues){
            // 画像ファイルは別途変換して、formDataにマッピングする
            if(key === "initial_image" || key === "image"){
                if(newValues[key] !== null){
                    var bin = atob((newValues[key] as string).replace(/^.*,/, ''));
                    var buffer = new Uint8Array(bin.length);
                    for (var i = 0; i < bin.length; i++) {
                        buffer[i] = bin.charCodeAt(i);
                    }
                    // 画像ファイルはformDataにそのままぶち込む
                    formData.append(key, new File([buffer.buffer], ".png", {type: "image/png"}));
                }
            }else{
                // その他変更したいカラムはupdateFieldsにぶち込む
                updateFields[key] = newValues[key];
            }
        }
      formData.append("updateFields", JSON.stringify(updateFields))


      const response = await fetch(`${process.env.REACT_APP_API_URL}/requests/${request_id ?? ""}`, {
        method: 'PUT',
        credentials: "include",
        body: formData
      });
  
      if (!response.ok) {
        throw new Error('Failed to delete idea');
      }

      return response.json();
    });
};