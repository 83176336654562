import React, { useState, useEffect, useContext } from 'react';
import { Stack, Typography, TextField, Button, InputAdornment, Tabs, Tab, Drawer, Divider, Input, IconButton } from '@mui/material';
import reidea_logo from "./../img/RE-IDEA.png"
import SearchIcon from '@mui/icons-material/Search';

import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import ProfilePopover from './Popover/profile';
import { useSearchParams } from "react-router-dom";

import { MyContext } from '../routes/router';
import UserIconAnchored from './User/iconAnchored';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

import HeaderSP from './reidea_header_sp';
import { useIsMobile } from './isMobile';
import { simple } from '../interfaces/chat';
import { GetViewfindbysess } from './API/views';
import { useGetUsers } from './API/users';
import { UseQueryResult } from 'react-query';

const theme = createTheme({
  palette: {
      secondary: {
        main: '#383E86',
      },
      text: {
        secondary: '#AAAAAA', // タブが選択されていない状態のテキスト色
      },
  },
});


const Header: React.FC = () => {

  const { minWidth, user, mode, openRegisterPopup, openLoginPopup, openBetaLimitPopup } = React.useContext(MyContext);
  const [searchValue, setSearchValue] = useState(""); // 入力された値を保持する状態
  const [anchorEL, setAnchorEL] = React.useState<HTMLElement | null>(null);
  const [anchorSearch, setAnchorSearch] = React.useState<HTMLElement | null>(null);

  const [searchParams] = useSearchParams();
  // registerIDという名前のuserID
  const registerID = searchParams.get("registerID");
  // APIから情報を取得
  const {data: provisional_user } : UseQueryResult<simple | null, Error> & { queryKey: string } = useGetUsers("users_simple", "simple", registerID ?? "none", );
  
  useEffect(() => {
    // 仮登録しているユーザーが存在するならば、登録
    if(provisional_user?.user_id !== undefined){
      openRegisterPopup()
    }
  }, [registerID, provisional_user]);


  //モバイル端末かどうかを判定
  const isMobile = useIsMobile();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if(newValue === 0 && mode !== 0){
      window.location.href="/"
    }
    if(newValue === 1 && mode !== 1){
      window.location.href="/search?mode=idea"
    }
    if(newValue === 2 && mode !== 2){
      // window.location.href="/search?mode=product"
      openBetaLimitPopup()
    }
  };


  return(
    isMobile ? <HeaderSP/> :
    (<Stack alignItems={"center"} justifyContent={"space-between"} direction={"row"} minWidth={minWidth} sx={{height:"70px",top:0, backgroundColor:"white", position:"sticky", zIndex:10}} width={"100%"}>
        <Stack borderBottom={"1px solid rgba(220, 220, 220, 0.6)"} justifyContent={"space-between"} pl={6} pr={3} sx={{height:"100%", width:"100%"}}>
          <Stack mt={1} direction="row" justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
            <Stack direction="row" spacing={3} alignItems={"center"} sx={{cursor:"pointer"}} onClick={() => {window.location.href="/"}}>
              <img src={reidea_logo} alt="" style={{height: "55px"}}/>
            </Stack>
            
            <Stack direction={"row"} spacing={5} height={"100%"}>
              {window.innerWidth > 1000 &&
                <ThemeProvider theme={theme}>
                  <Tabs
                      value={mode}
                      onChange={handleChange}
                      textColor="secondary"
                      indicatorColor="secondary"
                      sx={{
                        "& .MuiTabs-indicator": {
                          height: 6,
                        },
                      }}
                    >
                    <Tab value={0} label="TOP" sx={{fontSize:14, fontWeight:"bold", color:"#383E86"}} />
                    <Tab value={1} label="みんなのIDEA" sx={{fontSize:14, fontWeight:"bold", color:"#383E86"}} />
                    <Tab value={2} label="新商品" sx={{fontSize:14, fontWeight:"bold", color:"#383E86"}} />
                  </Tabs>
                </ThemeProvider>
              }

          <Stack direction="row" alignItems={"center"} spacing={3} height={"100%"}>
            <Stack onClick={(e: React.MouseEvent<HTMLElement>)=>{setAnchorSearch(e.currentTarget)}}>
              <SearchIcon sx={{ cursor:"pointer", color:"#383E86", fontSize:36, }}/>
            </Stack>
            
            {user?.user_id === undefined &&
              <Button variant='outlined' onClick={() => {openLoginPopup()}} sx={{fontSize:12, fontWeight:"bold", color: "#383E86", borderRadius:"24px"}}>ログイン</Button>
            }
            {user?.user_id === undefined &&
                <Button variant='contained' onClick={()=>{openRegisterPopup()}} sx={{fontSize:12, fontWeight:"bold", backgroundColor: "#383E86", borderRadius:"24px"}}>新規会員登録</Button>
            }
            {user?.user_id !== undefined &&
                <UserIconAnchored user_name={user?.user_name ?? ""} onClick={() => {window.location.href="/"}}
                                  profile_image_name={user?.profile_image_name}
                                  profile_image_uri={user?.profile_image_uri}
                                  setAnchorEL={setAnchorEL}
                                  radius={24}/>
            }
          </Stack>
          
          <ProfilePopover user_name={user?.user_name ?? ""} profile_image_name={user?.profile_image_name ?? null} profile_image_uri={user?.profile_image_uri ?? null} user_type={user?.type ?? null} user_id={user?.user_id} anchorEL={anchorEL} setAnchorEL={setAnchorEL}/>
          </Stack>
        </Stack>
      </Stack>

        <Drawer
          anchor={"right"}
          open={Boolean(anchorSearch)}
          onClose={()=>{setAnchorSearch(null)}}
        >
          <Stack p={4} sx={{width:400}}>
            {/* バツボタン */}
            <IconButton sx={{position:"absolute", top:12, right:12, height:"44px", width:"44px"}} onClick={()=>{setAnchorSearch(null)}}>
              <CloseIcon sx={{fontSize:"32px"}}/> 
            </IconButton>
            {/* タイトル */}
            <Typography component="div" textAlign={"left"} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Box sx={{ color: "#383E86", fontSize:18, fontWeight: 'bold',  }}>キーワード</Box>
              <Box sx={{ marginLeft:"5px", color: "#383E86", fontSize:14, fontWeight: 'bold',  }}>から検索する</Box>
            </Typography>
            <TextField variant='outlined' value={searchValue} onChange={(e) => {setSearchValue(e.target.value)}}
              placeholder='キーワードを入力してください'
              onKeyPress={(e) => {
                if(e.key === 'Enter') {
                  window.location.href="/search?mode=idea&q="+searchValue;
                }
              }}
              sx={{'& .MuiInputBase-root.MuiOutlinedInput-root': {
                padding: 0,
              },}}
              InputProps={{
                style:{fontSize:"14px"},
                endAdornment: (
                    <Stack justifyContent={"center"} 
                      onClick={(e)=>{
                        e.stopPropagation();
                        window.location.href="/search?mode=apply&q="+searchValue;
                      }} alignItems={"center"} sx={{cursor:"pointer", width:"62px",height:"52px", borderRadius:"2px", backgroundColor:"#383E86",}}>
                      <SearchIcon sx={{fontSize:24, color:"white"}} />
                    </Stack>
                ),
              }}
            />

            <Typography  component="div" width={"100%"} sx={{marginTop:"20px", letterSpacing: '2px'}}>
              <Box sx={{color: "#666666", fontSize:12, fontWeight: 'bold',  }}>
                人気のキーワード
              </Box>
            </Typography>
            <Typography component="div" width={"100%"} sx={{marginLeft:"10px", display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop:"5px", letterSpacing: '0px'}}>
              {["カバン", "携帯", "レーザー", "中井", "共創型クラウドファンディング", "江戸切子"].map((item, index) => (
                <React.Fragment key={index}>
                  {index !== 0 &&
                    <Box component="span" sx={{ color: "#636363", margin:"auto 8px", fontSize: 12, fontWeight: 'bold' }}>
                      /
                    </Box>
                  }
                  <Box component="span" onClick={()=>{window.location.href="/search?mode=apply&q="+item}} sx={{ fontWeight: 'bold', color: "#636363",fontSize: 12, cursor:"pointer", ":hover":{textDecoration:"underline"}}}>
                    {item}
                  </Box>
                </React.Fragment>
              ))}
            </Typography>

            <Typography component="div" textAlign={"left"} sx={{ marginTop:"50px", display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Box sx={{ color: "#383E86", fontSize:18, fontWeight: 'bold',  }}>カテゴリー</Box>
              <Box sx={{ marginLeft:"5px", color: "#383E86", fontSize:14, fontWeight: 'bold',  }}>から検索する</Box>
            </Typography>

            <Typography component="div" width={"100%"} sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop:"5px", letterSpacing: '0px'}}>
              {["日用品", "ガジェット", "ゲーム", "文房具", "玩具", "キッチン用品", "便利グッズ", "キッチン用品", "音楽用品"].map((item, index) => (
                <React.Fragment key={index}>
                  <Box component="span" onClick={()=>{
                              const queryString = new URLSearchParams({ categories: JSON.stringify([item]) }).toString();
                              window.location.href="/search?mode=apply&"+queryString}}
                              sx={{ transition: 'color 0.3s, background 0.3s', ":hover":{backgroundColor:"#808080", color:"white"}, margin:"0px 6px 5px 0px", borderRadius:"15px", padding:"2px 20px", cursor:"pointer", color: "#808080", backgroundColor:"#e6e6e6", fontSize: 12, fontWeight: 'bold'}}>
                    {item}
                  </Box>
                </React.Fragment>
              ))}
            </Typography>
          </Stack>
        </Drawer>
      </Stack>)
    )
}

export default Header;