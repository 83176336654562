import axios from 'axios';
import { UseMutationResult, UseQueryResult, useMutation, useQuery } from 'react-query';
import { text } from '../../interfaces/text';

interface PutProjectPayload {
  project_id: string | null;
  newValues: { [key: string]: string | text | Blob | boolean | undefined };
}



// GET POST PUT DELTEの4種類のみで構成されていることが理想


// GET /projects/type/project_id or query
export const useGetProjects = <T,>(query_key: string, project_id: string | null, query?: string): UseQueryResult<T, Error> & { queryKey: string } => {
    // ideas/ simple or medium (/) idea_id or (?) query
    const API_END_POINT_PASS = `/projects${project_id ?  "/"+project_id : ""}${query ? "?"+query : ""}`
    const QUERY_KEY = query_key;
    return {
      ...useQuery([QUERY_KEY, project_id, query], () => fetch(process.env.REACT_APP_API_URL+API_END_POINT_PASS,{credentials: 'include'},).then(res =>res.json()), {
        enabled: query_key !== ""
      }),
      queryKey:QUERY_KEY,
      
    };
}

// GET /projects/type/project_id or query
export const useGetProjectsCount = <T,>(query_key: string, ): UseQueryResult<T, Error> & { queryKey: string } => {
  const API_END_POINT_PASS = `/projects/count`
  const QUERY_KEY = query_key;
  return {
    ...useQuery([QUERY_KEY], () => fetch(process.env.REACT_APP_API_URL+API_END_POINT_PASS,{credentials: 'include'},).then(res =>res.json()), {
      enabled: query_key !== ""
    }),
    queryKey:QUERY_KEY,
    
  };
}

// DELETE /projects/project_id
export const useDeleteProject = (): UseMutationResult<void, Error, string> => {
  return useMutation(async (project_id: string) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/projects/${project_id}`, {
      method: 'DELETE',
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error('Failed to delete idea');
    }
  });
};

// PUT /projects/project_id
export const usePutProject = (): UseMutationResult<void, Error, PutProjectPayload> => {
  return useMutation(async ({ project_id, newValues }: PutProjectPayload) => {
      // newValuesで指定されているFile系の値を送信用に変換したりする
      const formData = new FormData();
      const base64Pattern = /^data:image\/(png|jpg|jpeg);base64,/;

      // "file" という名前のキーを除外した新しいオブジェクトを作成
      const updateFields: { [key: string]: any } = {};
      for(let key in newValues){
        if(newValues[key] === undefined){
          continue;
        }
          // 画像ファイルは別途変換して、formDataにマッピングする
          if (["image_slot1", "image_slot2", "image_slot3", "image_slot4", "image_slot5"].includes(key)) {
            const value = newValues[key];
            // base64形式の場合のみ
            if (value !== null && typeof value === "string" && base64Pattern.test(value)) {
              const bin = atob(value.replace(base64Pattern, ""));
              const buffer = new Uint8Array(bin.length);
              for (let i = 0; i < bin.length; i++) {
                buffer[i] = bin.charCodeAt(i);
              }
              formData.append(key, new File([buffer.buffer], ".png", { type: "image/png" }));
            }else if(value === null){
              updateFields[key] = null;
            }
          }else{
              // その他変更したいカラムはupdateFieldsにぶち込む
              updateFields[key] = newValues[key];
          }
      }
    formData.append("updateFields", JSON.stringify(updateFields))


    const response = await fetch(`${process.env.REACT_APP_API_URL}/projects/${project_id ?? ""}`, {
      method: 'PUT',
      credentials: "include",
      body: formData
    });

    if (!response.ok) {
      throw new Error('Failed to delete idea');
    }

    return response.json();
  });
};


// GET /projects/copy/:project_id
export const useCopyProject = (): UseMutationResult<void, Error, {project_id:string}> => {
  return useMutation(async ({ project_id }: {project_id:string}) => {
      
    const response = await fetch(`${process.env.REACT_APP_API_URL}/projects/copy/${project_id ?? ""}`, {
      method: 'GET',
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error('Failed to delete idea');
    }

    return response.json();
  });
};



